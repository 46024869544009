import { useMemo } from "react";
import useBusiness from "./useBusiness";

const useSubscriptions = () => {
  const { activeBusiness } = useBusiness();

  const stripePrices = useMemo(
    () => ({
      scheduler: {
        free: process.env.REACT_APP_STRIPE_SCHEDULER_FREE_PRICE_ID,
        essential: process.env.REACT_APP_STRIPE_SCHEDULER_ESSENTIAL_PRICE_ID,
        premium: process.env.REACT_APP_STRIPE_SCHEDULER_PREMIUM_PRICE_ID,
        enterprise: process.env.REACT_APP_STRIPE_SCHEDULER_ENTERPRISE_PRICE_ID,
      },
      onboarding: {
        fixed: {
          free: process.env.REACT_APP_STRIPE_ONBOARDING_FIXED_FREE_PRICE_ID,
          essential: process.env.REACT_APP_STRIPE_ONBOARDING_FIXED_ESSENTIAL_PRICE_ID,
          premium: process.env.REACT_APP_STRIPE_ONBOARDING_FIXED_PREMIUM_PRICE_ID,
          enterprise: process.env.REACT_APP_STRIPE_ONBOARDING_FIXED_ENTERPRISE_PRICE_ID,
        },
        metered: {
          free: process.env.REACT_APP_STRIPE_ONBOARDING_METERED_FREE_PRICE_ID,
          essential: process.env.REACT_APP_STRIPE_ONBOARDING_METERED_ESSENTIAL_PRICE_ID,
          premium: process.env.REACT_APP_STRIPE_ONBOARDING_METERED_PREMIUM_PRICE_ID,
          enterprise: process.env.REACT_APP_STRIPE_ONBOARDING_METERED_ENTERPRISE_PRICE_ID,
        },
      },
      ondemand: {
        free: process.env.REACT_APP_STRIPE_ONDEMAND_FREE_PRICE_ID,
        essential: process.env.REACT_APP_STRIPE_ONDEMAND_ESSENTIAL_PRICE_ID,
        premium: process.env.REACT_APP_STRIPE_ONDEMAND_PREMIUM_PRICE_ID,
        enterprise: process.env.REACT_APP_STRIPE_ONDEMAND_ENTERPRISE_PRICE_ID,
      },
    }),
    []
  );

  const currrentSubscription = useMemo(() => {
    if (!activeBusiness) return {};
    const {
      subscription_items: { OnDemand, Onboarding, Scheduler, id },
    } = activeBusiness;
    return {
      id,
      scheduler: {
        price: Scheduler?.price?.id,
        id: Scheduler?.id,
      },
      onboarding: {
        fixed: {
          price: Onboarding?.fixed?.price?.id,
          id: Onboarding?.fixed?.id,
        },
        metered: {
          price: Onboarding?.metered?.price?.id,
          id: Onboarding?.metered?.id,
        },
      },
      ondemand: {
        price: OnDemand?.price?.id,
        id: OnDemand?.id,
      },
    };
  }, [activeBusiness]);

  return { prices: stripePrices, current: currrentSubscription };
};

export default useSubscriptions;
