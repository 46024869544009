import { useState, useLayoutEffect, useCallback } from "react";
import styles from "./XeroIntegrationBase.module.css";
import { useStyling, useFetch, useAlerts, useToast, LoadingSpinner, ObjectID } from "shiftly-ui";
import StandardLayout from "src/pages/settings/components/StandardLayout";
import IntegrationStep from "../IntegrationStep";
import { faPlug, faSpinner, faStore } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IntegrationMap from "../IntegrationMap";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import { useNavigate } from "react-router-dom";
import useBusiness from "src/hooks/useBusiness";
import XeroButton from "../../../assets/xero-connect-white.svg";

const stateTimezones = {
  NSW: "Australia/Sydney", // New South Wales
  VIC: "Australia/Melbourne", // Victoria
  QLD: "Australia/Brisbane", // Queensland
  SA: "Australia/Adelaide", // South Australia
  WA: "Australia/Perth", // Western Australia
  TAS: "Australia/Hobart", // Tasmania
  NT: "Australia/Darwin", // Northern Territory
  ACT: "Australia/Sydney", // Australian Capital Territory (same as NSW)
};

const XeroIntegrationBase = ({ activeIntegration, isLoading, connectionLink, xeroOrganisations = [] }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [mappedLocations, setMappedLocations] = useState({});
  const [updateIsLoading, setUpdateIsLoading] = useState(false);

  const { activeBusiness } = useBusiness();
  const toast = useToast();
  const { refreshAvailableLocations, allLocations } = useShiftlyLocation();
  const { confirm } = useAlerts();
  const navigate = useNavigate();

  const { post: disconnectOrganisation, updateCache } = useFetch({
    options: {
      onMutate: ({ data }) => {
        updateCache("Integration.GetXeroElements", (oldData = {}) => {
          return { ...oldData, organisations: oldData.organisations?.filter((org) => org.id !== data.id) };
        });
        setMappedLocations((prev) => {
          const { [data.id]: _, ...rest } = prev;
          return rest;
        });
      },
    },
  });

  const { post: updateIntegration, refresh } = useFetch({
    options: {
      onSuccess: (data) => {
        refresh("Integration.GetXeroElements");
        refresh("Integration.GetAllIntegrations");
        refresh("Integration.GetActiveIntegrationByType");

        setUpdateIsLoading(false);
        toast.success("Integration updated successfully");
        navigate("/integrations");
      },
      onError: () => setUpdateIsLoading(false),
    },
  });

  const { post: createLocationFromXero } = useFetch({
    options: {
      onMutate: ({ data, payload = {} }) => {
        const { orgID } = payload;
        setMappedLocations((prev) => ({ ...prev, [orgID]: data._id }));
      },
      onSuccess: () => refreshAvailableLocations(),
      onError: () => toast.error("Failed to create location. Please continue manually."),
    },
  });

  useLayoutEffect(() => {
    if (activeIntegration) {
      setMappedLocations(activeIntegration?.locationMap || {});
    }
  }, [activeIntegration]);

  useLayoutEffect(() => {
    if (activeIntegration) {
      setActiveStep(2);
    }
  }, [activeIntegration]);

  const handleDisconnectOrg = useCallback(
    async (org) => {
      if (
        !(await confirm({
          label: "Disconnect Organisation",
          text: `Are you sure you want to disconnect ${org?.name} from Shiftly?`,
          confirmText: "Disconnect",
          cancelText: "Cancel",
          inverse: true,
        }))
      )
        return;

      disconnectOrganisation({
        entity: "Integration",
        method: "disconnect",
        data: {
          id: org.id,
          type: "xeroOrganisation",
          business: activeBusiness?._id,
        },
      });
    },
    [confirm, disconnectOrganisation, activeBusiness]
  );

  const handleCreateLocation = useCallback(
    async (organisation = {}) => {
      const { address } = organisation;

      if (address) {
        const timezone = stateTimezones[address.region];
        if (
          timezone &&
          (await confirm({
            label: "Create Location",
            text: `This organisation can be imported from Xero, would you like to create a location for it or do it manually?`,
            confirmText: "Import from Xero",
            cancelText: "Create Manually",
          }))
        ) {
          createLocationFromXero({
            entity: "Location",
            method: "create",
            payload: {
              orgID: organisation.id,
            },
            data: {
              _id: ObjectID(),
              name: organisation.name,
              address: {
                street: address?.addressLine1,
                city: address?.city,
                state: address?.region,
                postalCode: address?.postalCode,
                country: address?.country ?? "Australia",
              },
              business: activeBusiness?._id,
              timezone,
            },
          });
          return;
        }
      }

      navigate("/business-account/locations/edit-location");
    },
    [navigate, confirm, createLocationFromXero, activeBusiness]
  );

  const xeroButton = <ConnectToXeroButton activeIntegration={activeIntegration} connectionLink={connectionLink} />;

  const handleMapLocations = useCallback(async () => {
    if (Object.keys(mappedLocations).length !== xeroOrganisations.length) {
      if (
        !(await confirm({
          label: "Incomplete Mapping",
          text: `It seems like you haven't mapped all Xero organisations to Shiftly locations. Any unmapped organisations will be disconnected. Are you sure you want to continue?`,
          confirmText: "Continue",
          cancelText: "Cancel",
        }))
      )
        return;
    }

    setUpdateIsLoading(true);
    const organisationsToDisconnect = xeroOrganisations.filter((org) => !mappedLocations[org.id]);

    updateIntegration([
      {
        entity: "Integration",
        method: "update",
        criteria: { _id: activeIntegration?._id },
        data: {
          locationMap: mappedLocations,
        },
      },
      ...organisationsToDisconnect.map((org) => ({
        entity: "Integration",
        method: "disconnect",
        data: {
          id: org.id,
          type: "xeroOrganisation",
          business: activeBusiness?._id,
        },
      })),
    ]);
  }, [mappedLocations, xeroOrganisations, confirm, updateIntegration, activeIntegration, activeBusiness]);

  return (
    <StandardLayout
      heading={<h1>Xero Integration</h1>}
      description={<p></p>}
      breadcrumb={"Business Profile / Integrations /"}
      returnLink={"/integrations"}
      size={"medium"}
    >
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "300px" }}>
          <LoadingSpinner loader={isLoading}> </LoadingSpinner>
        </div>
      ) : (
        <>
          <IntegrationStep
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            status={activeIntegration ? "complete" : "open"}
            number={1}
            name={"Connect to Xero"}
            icon={faPlug}
            description={`To connect your Xero account with Shiftly, you'll need to authenticate via OAuth. When you click on the Connect button, you'll be redirected to the Xero login page. Here, you'll log in with your Xero credentials and authorise Shiftly to access your Xero organisation. Once authorised, you'll be redirected back to Shiftly, where your Xero account will be securely connected. `}
            customButton={xeroButton}
            completeButton={xeroButton}
          ></IntegrationStep>
          <IntegrationStep
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            status={activeStep === 1 ? "incomplete" : "open"}
            number={2}
            name={"Map Locations"}
            icon={faStore}
            buttonText={updateIsLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Save"}
            buttonAction={handleMapLocations}
            description={`In this section, you'll need to map Xero organisations to their corresponding Shiftly locations. If a Shiftly location doesn't exist yet, please create it before completing the mapping. Any unmapped organisations will be disconnected.`}
          >
            <IntegrationMap
              toLink={xeroOrganisations}
              options={allLocations}
              mode={"locations"}
              data={mappedLocations}
              setData={setMappedLocations}
              setActiveStep={setActiveStep}
              onDelete={handleDisconnectOrg}
              onCreate={handleCreateLocation}
            />
          </IntegrationStep>
        </>
      )}
    </StandardLayout>
  );
};

const ConnectToXeroButton = ({ activeIntegration, connectionLink }) => {
  const handleButtonClick = useCallback(() => {
    window.open(connectionLink, "_self");
  }, [connectionLink]);

  const styling = useStyling(styles);

  return (
    <div className={styling("button-container", activeIntegration && "complete")} onClick={handleButtonClick}>
      <img src={XeroButton} alt="Xero Connect Button" className={styling("xero-button")}></img>
    </div>
  );
};

export default XeroIntegrationBase;
