import { useCallback, useMemo } from "react";
import { useDrag, useDrop } from "react-dnd";
import moment from "moment-timezone";
import useShiftManager from "src/hooks/useShiftManager";

const useShiftDrag = ({ shift, position, index, periods, status }) => {
  const { handleTimeUpdate, normaliseShift, updateShifts, upgradeShift, downgradeShift } = useShiftManager();

  const handleDropShift = useCallback(
    async (item) => {
      const { shift: draggedShift, position: draggedPosition } = item;

      //Exit anything external or invalid
      if (draggedPosition.type === "external" || position?.type === "external" || !draggedShift._id) return;

      const slotDay = moment.tz(periods.current[index], draggedShift?.timezone);
      const { newStartTime, newEndTime } = handleTimeUpdate(draggedShift, slotDay);

      const newShift = {
        ...draggedShift,
        ...normaliseShift(draggedShift),
        position: position?._id,
        position_group: position?.group?._id,
        start_time: newStartTime,
        end_time: newEndTime,
        type: position?.type,
      };

      //Dropping internal shift on external slot
      if (position.type === "shiftly" && draggedPosition?.type === "internal") {
        upgradeShift(newShift);

        //Dropping external shift on internal slot
      } else if (position?.type === "internal" && draggedPosition?.type === "shiftly") {
        downgradeShift(newShift);

        //Dropping external shift on external slot or internal shift on internal slot
      } else {
        updateShifts([newShift]);
      }
    },
    [updateShifts, periods, index, position, handleTimeUpdate, normaliseShift, upgradeShift, downgradeShift]
  );

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "Shift",
      item: { shift, index, position },
      collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
    }),
    [shift, index, position]
  );

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "Shift",
      drop: (item) => handleDropShift(item),
      collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    }),
    [handleDropShift]
  );

  const dragRef = useMemo(() => {
    const undraggable = ["confirmed", "expired", "external"];
    if (status === "add" || undraggable.includes(shift.status)) return null;
    return drag;
  }, [drag, status, shift.status]);

  return useMemo(
    () => ({
      dragRef,
      dropRef: drop,
      isDragging,
      isOver,
    }),
    [dragRef, drop, isDragging, isOver]
  );
};
export default useShiftDrag;
