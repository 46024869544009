import { useAuth, useStyling, Each, Button, withDisplay } from "shiftly-ui";
// Import the useStyling hook
import styles from "./BusinessNavDropDown.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { useNavigate, useLocation } from "react-router-dom";
import {
  faCoins,
  faGear,
  faGrid2,
  faHeadset,
  faLocationDot,
  faPlug,
  faShuffle,
  faStore,
  faUserMagnifyingGlass,
} from "@fortawesome/pro-regular-svg-icons";
import useBusiness from "src/hooks/useBusiness";

const links = [
  {
    icon: faShuffle,
    text: "All Businesses",
    link: "/",
  },
  { icon: faUserMagnifyingGlass, text: "Your Details", link: "/account-settings/your-details" },
  {
    icon: faHeadset,
    text: "Resources and Support",
    link: "https://shiftly.au/resources",
    type: "external",
  },
];

const businessLinks = [
  {
    icon: faLocationDot,
    text: "Locations",
    link: "/business-account/locations",
  },
  { icon: faCoins, text: "Billing & Payment", link: "/business-account/billing-and-payment" },
  {
    icon: faPlug,
    text: "Integrations",
    link: "/integrations",
  },
];

const BusinessNavDropDown = ({ open, setOpen, isTablet }) => {
  const { user } = useAuth();
  const location = useLocation();
  const styling = useStyling(styles);

  const [activeNav, setActiveNav] = useState({});

  const navigate = useNavigate();
  const { activeBusiness } = useBusiness();

  useEffect(() => {
    const path = window.location.pathname;
    setActiveNav(links.find((link) => link.link === path));
  }, [location]);

  return (
    <>
      {open && <div className={styling("overlay")} onClick={() => setOpen(false)}></div>}
      {!isTablet && (
        <div className={styling("container")}>
          <div className={styling("icon-container")} onClick={() => setOpen((prev) => !prev)}>
            <div className={styling("account-icon")}>
              <p>
                {user?.first_name?.charAt(0)?.toUpperCase()}
                {user?.last_name?.charAt(0)?.toUpperCase()}
              </p>
            </div>
            <FontAwesomeIcon
              icon={faChevronDown}
              className={clsx(styling("icon-chevron"), open && styling("spin-chevron"))}
            />
          </div>
        </div>
      )}
      <div className={clsx(styling("menu-container"), open && styling("show"))}>
        {activeBusiness && (
          <div className={styling("nav-section")}>
            <p className={styling("menu-title")}>{activeBusiness?.name}</p>
            <div
              className={clsx(styling("parent-nav-item"), styling("home"))}
              onClick={() => {
                navigate("/shifts");
                setOpen(false);
              }}
            >
              <FontAwesomeIcon icon={faGrid2} />
              <p className={styling("link-text")}>Home</p>
            </div>
            <div
              className={styling("parent-nav-item")}
              onClick={() => {
                navigate("/business-account");
                setOpen(false);
              }}
            >
              <FontAwesomeIcon icon={faStore} />
              <p className={styling("link-text")}>Business Settings</p>
            </div>
            <Each
              of={businessLinks}
              render={({ key, ...link }) => (
                <SubNavItem {...link} key={key} active={activeNav?.text === link.text} setOpen={setOpen} />
              )}
            />
          </div>
        )}
        <div className={styling("nav-section")}>
          <div
            className={styling("parent-nav-item")}
            onClick={() => {
              navigate("/account-settings");
              setOpen(false);
            }}
          >
            <FontAwesomeIcon icon={faGear} />
            <p className={styling("link-text")}>Account Settings</p>
          </div>
          <Each
            of={links}
            render={({ key, ...link }) => (
              <SubNavItem {...link} key={key} active={activeNav?.text === link.text} setOpen={setOpen} />
            )}
          />
        </div>
        <div className={styling("logout-container")}>
          <Button
            theme={"secondary"}
            className={styling("logout-btn")}
            onClick={() => {
              localStorage.removeItem("shiftly-auth-token");
              window.location.reload();
            }}
          >
            LOG OUT
          </Button>
        </div>
      </div>
    </>
  );
};
const SubNavItem = ({ icon, text, link, active, setOpen, type }) => {
  const navigate = useNavigate();
  const styling = useStyling(styles);
  return (
    <div className={styling("sub-nav-item")}>
      <div className={styling("sub-nav-side")}></div>
      <div
        className={clsx(styling("sub-nav-content"), active && styling("active"))}
        onClick={() => {
          setOpen(false);
          if (type === "external") return window.open(link, "_blank");
          navigate(link);
        }}
      >
        <FontAwesomeIcon icon={icon} className={styling("sub-nav-icon")} />
        <p className={styling("link-text")}>{text}</p>
      </div>
    </div>
  );
};
export default withDisplay(BusinessNavDropDown);
