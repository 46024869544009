import { Input, Each, BigToggle, useStyling, withDisplay } from "shiftly-ui";
import styles from "./SearchAndDisplay.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrid2, faList } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import Skeleton from "react-loading-skeleton";
import { useUncontrolled } from "uncontrollable";

const SearchAndDisplay = (props) => {
  const {
    isMobile,
    tabs = [],
    tab = 0,
    setTab = () => {},
    onTabChange = () => {},
    elements = [],
    elementsName = "",
    searchPlaceholder = "Search...",
    searchFields = [],
    isLoading = false,
    hideToggle = false,
    defaultView = "grid",
    hideSearchBar = false,
    link = {},
    emptyStateText = (
      <p>
        This space is empty.
        <br />
        Check back again later!
      </p>
    ),
  } = useUncontrolled(props, {
    tab: "setTab",
  });

  const [listMode, setListMode] = useState(defaultView);
  const [searchText, setSearchText] = useState("");
  const [filteredElements, setFilteredElements] = useState(elements);
  const [parent] = useAutoAnimate();
  const styling = useStyling(styles);

  useEffect(() => {
    setFilteredElements(
      searchText
        ? elements.filter((element) =>
            searchFields.some((field) =>
              element["original"]?.[field]?.toLowerCase()?.includes(searchText.toLowerCase())
            )
          )
        : elements
    );
  }, [searchText, elements, searchFields]);

  useEffect(() => {
    onTabChange(tab);
  }, [tab, onTabChange]);

  return (
    <div className={styling("container")}>
      {!isMobile && tabs.length > 0 && (
        <div className={styling("tabs-container")}>
          <BigToggle tabs={tabs} tab={tab} setTab={setTab} />
        </div>
      )}
      <div className={styling("search-container")}>
        {!hideSearchBar && (
          <div className={styling("search-input")}>
            <Input
              label={"Search " + elementsName}
              setValue={setSearchText}
              value={searchText}
              placeholder={searchPlaceholder}
              icon={{ icon: faMagnifyingGlass, side: "right" }}
              link={link}
            />
          </div>
        )}
        {!hideToggle && (
          <div className={styling("search-controllers")}>
            <SearchControlButton icon={faGrid2} active={listMode === "grid"} mode={"grid"} setListMode={setListMode} />
            <SearchControlButton icon={faList} active={listMode === "list"} mode={"list"} setListMode={setListMode} />
          </div>
        )}
      </div>
      {!isLoading ? (
        <div className={styling("elements-container", listMode)} ref={parent}>
          {elements.length > 0 ? (
            listMode === "grid" ? (
              <Each of={filteredElements} render={({ key, ...element }) => <ElementCard {...element} key={key} />} />
            ) : (
              <Each of={filteredElements} render={({ key, ...element }) => <ElementList {...element} key={key} />} />
            )
          ) : (
            <div className={styling("no-elements")}>{emptyStateText}</div>
          )}
        </div>
      ) : (
        <div className={styling("loading-container")}>
          <div className={styles[("element-card", "skeleton")]}>
            <Skeleton height={"50%"} width={"100%"} className={styles["skeleton-image"]} />
            <Skeleton count={1} height={"40px"} className={styles["skeleton-text"]} />
            <Skeleton count={3} height={"20px"} className={styles["skeleton-text"]} />
          </div>
          <div className={styles[("element-card", "skeleton")]}>
            <Skeleton height={"50%"} width={"100%"} className={styles["skeleton-image"]} />
            <Skeleton count={1} height={"40px"} className={styles["skeleton-text"]} />
            <Skeleton count={3} height={"20px"} className={styles["skeleton-text"]} />
          </div>
        </div>
      )}
    </div>
  );
};
const SearchControlButton = ({ icon, active = false, setListMode, mode }) => {
  const styling = useStyling(styles);
  return (
    <div className={styling("control-button", active && "active-control")} onClick={() => setListMode(mode)}>
      <FontAwesomeIcon icon={icon} />
    </div>
  );
};
const ElementCard = ({ content, footerContent }) => {
  const styling = useStyling(styles);
  return (
    <div className={styling("element-card")}>
      <div className={styling("element-content")}>{content}</div>
      <div className={styling("element-footer")}>{footerContent}</div>
    </div>
  );
};
const ElementList = ({ listContent }) => {
  const styling = useStyling(styles);
  return (
    <div className={styling("list-card")}>
      <div className={styling("list-content")}>{listContent}</div>
    </div>
  );
};
export default withDisplay(SearchAndDisplay);
