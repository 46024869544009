import { useStyling, Button } from "shiftly-ui";
import styles from "./IntegrationStep.module.css";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const iconVariants = {
  complete: {
    left: "20px",
    transform: "translateX(0%)",
    background: "var(--success-50)",
    color: "var(--success-500)",
    width: "60px",
    height: "60px",
  },
  open: {
    left: "50%",
    transform: "translateX(-50%)",
    background: "var(--orange-gradient)",
    color: "white",
    width: "80px",
    height: "80px",
  },
  incomplete: {
    left: "20px",
    transform: "translateX(0%)",
    background: "var(--secondary-300)",
    color: "var(--secondary-800)",
    width: "60px",
    height: "60px",
    fontSize: "20px",
    borderRadius: "50%",
  },
};

const IntegrationStep = ({
  number = 1,
  name = "",
  description = "",
  icon,
  setActiveStep,
  children,
  buttonText = "",
  buttonAction = () => {},
  disabled,
  status = "open",
  completeButton,
  customButton,
}) => {
  const styling = useStyling(styles);

  return (
    <div className={styling("container", status)} onClick={() => status !== "incomplete" && setActiveStep(number)}>
      <motion.div
        variants={iconVariants}
        animate={status}
        initial={status}
        className={styling("icon-container", status, "no-color-transition")}
      >
        <FontAwesomeIcon icon={icon} />
      </motion.div>
      <div className={styling("heading", status)}>
        <h1>
          <span>Step {number}:</span> {name}
        </h1>
        {status === "complete" && completeButton}
        {status === "open" && <p>{description}</p>}
      </div>
      {status === "open" && (
        <>
          <div className={styling("content")}>{children}</div>
          <div className={styling("button-container")}>
            {customButton ?? (
              <Button
                disabled={disabled}
                theme="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  buttonAction();
                }}
                className={styling("button")}
              >
                {buttonText}
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default IntegrationStep;
