import { useStyling, BigToggle, Button, useSearchParams, withDisplay } from "shiftly-ui";
import { useState } from "react";
import styles from "./People.module.css";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import ShiftlyApplicants from "./components/ShiftlyApplicants";
import { useNavigate } from "react-router-dom";
import InternalStaffTable from "./components/InternalStaffTable";

const People = ({ isMobile }) => {
  const [tab, setTab] = useState(1);
  const styling = useStyling(styles);

  const navigate = useNavigate();

  useSearchParams(
    ["tab"],
    ({ tab }) => {
      switch (tab) {
        case "shifters":
          setTab(0);
          break;
        case "internal":
          setTab(0);
          break;
        default:
          setTab(1);
          break;
      }
    },
    []
  );

  return (
    <>
      <div className={styling("container")}>
        {!isMobile && (
          <BigToggle tab={tab} setTab={setTab} tabs={[{ text: "Internal Staff" }, { text: "Shifters" }]} align="left">
            {tab === 0 && (
              <div className={styling("create-btn")}>
                <Button theme={"secondary"} icon={faPlus} onClick={() => navigate("/people/new-internal")}>
                  Add a staff member
                </Button>
              </div>
            )}
          </BigToggle>
        )}
        {tab === 0 && <InternalStaffTable />}
        {tab === 1 && <ShiftlyApplicants />}
      </div>
    </>
  );
};
export default withDisplay(People);
