import {
  useStyling,
  useFetch,
  Each,
  Table,
  RatingDisplay,
  calculateAge,
  Button,
  formatDateToPrettyTime,
  withDisplay,
  useSearchParams,
} from "shiftly-ui";

import { useCallback, useMemo, useRef, useState } from "react";
import styles from "./ShiftlyApplicants.module.css";
import { motion } from "framer-motion";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftToLine, faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import logo from "shiftly-ui/assets/logos/icon.svg";
import ActionsDropdown from "./ActionsDropdown";
import ApplicantsConfirm from "./Applicants/ApplicantsConfirm";
import ApplicantProfile from "./Applicants/ApplicantProfile";
import ApplicantCard from "./Applicants/ApplicantCard";
import Illustration from "shiftly-ui/assets/svg/Illustrations/NoShifts.svg";

const sidePanelVariants = {
  open: {
    width: "360px",
  },
  closed: {
    width: "0px",
  },
};
const ShiftlyApplicants = ({ isMobile }) => {
  const [activeShift, setActiveShift] = useState(null);
  const [sidePanelOpen, setSidePanelOpen] = useState(true);
  const [confirmingApplicant, setConfirmingApplicant] = useState(null);
  const [viewingApplicant, setViewingApplicant] = useState(null);
  const styling = useStyling(styles);
  const { activeLocation } = useShiftlyLocation();

  const tabRefSet = useRef();

  const {
    data: [paymentMethod],
    isFetching: paymentMethodIsFetching,
  } = useFetch({
    request: {
      entity: "PaymentMethod",
      method: "get",
      criteria: {
        is_default: true,
      },
      id: "PaymentMethod.GetDefaultPaymentMethod",
    },
  });

  const {
    post: actionApplicantPost,
    refresh,
    updateCache,
  } = useFetch({
    options: {
      onMutate: ({ data }) => {
        updateCache("ShiftApplication.GetApplicationsByLocation", (oldData = []) => {
          const index = oldData.findIndex((app) => app._id === data?.application_id);
          if (index !== -1) {
            oldData[index].status = data?.action;
          }
          return oldData;
        });
      },
      onError: () => {
        refresh("ShiftApplication.GetApplicationsByLocation");
        refresh("ShiftApplication.GetApplicationsForShifts");
        refresh("Shift.ShiftsBetweenDates");
      },
      onSuccess: () => {
        refresh("ShiftApplication.GetApplicationsByLocation");
        refresh("ShiftApplication.GetApplicationsForShifts");
        refresh("Shift.ShiftsBetweenDates");
      },
    },
  });

  const actionApplicant = useCallback(
    ({ application_id, action, shift, user }) => {
      actionApplicantPost({
        entity: "ShiftApplication",
        method: "actionApplicant",
        data: {
          application_id,
          shift_id: shift._id,
          user: user._id,
          action,
        },
      });
    },
    [actionApplicantPost]
  );

  const columns = useMemo(
    () => [
      {
        field: "status",
        headerName: "Status",
        sortable: true,
        align: "center",
        width: 100,
        render: (row) => {
          return <div className={styling("status-icon", row.status)}></div>;
        },
      },
      {
        field: "shifter",
        headerName: "Shifter",
        sortable: true,
        flex: 1,
        render: (row) => {
          return (
            <div className={styling("shifter-cell")} onClick={() => setViewingApplicant(row)}>
              <div className={styling("shifter-profile", row.status)}>
                <img src={row.profile?.profile_picture || logo} alt="profile" />
              </div>
              <p>{row.shifter}</p>
            </div>
          );
        },
      },
      {
        field: "rating",
        headerName: "Rating",
        sortable: true,
        width: 150,
        render: (row) => <RatingDisplay rating={row.rating} />,
        align: "left",
      },
      {
        field: "start_time",
        headerName: "Shift",
        sortable: true,
        width: 300,
        render: ({ shift }) => {
          return (
            <>
              <span className={styling("shift-date")}>
                {shift?.day_of_week} {shift?.prettyDate}
              </span>
              {shift.shiftPeriod}
            </>
          );
        },
      },
      { field: "age", headerName: "Age", sortable: true, width: 100, render: (row) => calculateAge(row.age) },
      {
        field: "applied",
        headerName: "Applied",
        sortable: true,
        width: 150,
        render: (row) => formatDateToPrettyTime(row.applied),
      },
      {
        field: "actions",
        headerName: "",
        align: "right",
        width: 300,
        render: ({ status, _id, shift, user, ...applicant }) => {
          if (!paymentMethod) return null;
          if (status === "rejected") return <FontAwesomeIcon icon={faTimes} className={styling("declined-mark")} />;
          if (status === "accepted") return <FontAwesomeIcon icon={faCheck} className={styling("check-mark")} />;
          return (
            status === "pending" && (
              <div className={styling("actions")}>
                <ActionsDropdown
                  actions={[
                    {
                      label: "Decline",
                      action: () => {
                        actionApplicant({ application_id: _id, user, shift, action: "rejected" });
                      },
                    },
                  ]}
                />
                <Button
                  className={styling("accept")}
                  onClick={() => {
                    setConfirmingApplicant({ _id, user, shift, ...applicant });
                  }}
                >
                  Accept
                </Button>
              </div>
            )
          );
        },
      },
    ],
    [styling, actionApplicant, paymentMethod]
  );

  const { data: shifts, isLoading: shiftsIsFetching } = useFetch({
    request: {
      entity: "Shift",
      method: "get",
      populate: ["position", "user"],
      criteria: {
        location: activeLocation?._id,
        status: {
          $in: isMobile ? ["published"] : ["published", "confirmed", "active"],
        },
      },
      options: {
        sort: {
          start_time: 1,
        },
      },
      id: "Shift.GetShiftsByLocation",
    },
    dependency: activeLocation?._id,
  });

  const criteria = useMemo(() => {
    if (activeShift) {
      return {
        shift: activeShift._id,
      };
    }
    if (isMobile) {
      return {
        status: "pending",
        location: activeLocation?._id,
      };
    }
    return {
      status: {
        $ne: "unverified",
      },
      location: activeLocation?._id,
    };
  }, [activeShift, activeLocation, isMobile]);

  const { data: applicants, isLoading: applicantsIsFetching } = useFetch({
    request: {
      entity: "ShiftApplication",
      method: "get",
      populate: [
        "user",
        {
          path: "shift",
          populate: ["position"],
        },
      ],
      criteria,
      id: "ShiftApplication.GetApplicationsByLocation",
    },
    dependency: activeShift?._id + activeLocation?._id,
    options: {
      enabled: true,
    },
  });

  const { data: profiles, isLoading: profilesIsFetching } = useFetch({
    request: {
      entity: "Profile",
      method: "get",
      criteria: {
        user: applicants.map((applicant) => applicant.user._id),
      },
    },
    dependency: applicants.length,
    options: {
      select: (profiles) => {
        return profiles.reduce((acc, profile) => {
          acc[profile.user] = profile;
          return acc;
        }, {});
      },
    },
  });

  useSearchParams(
    ["shift", "application"],
    ({ shift, application }) => {
      if (tabRefSet.current) return;

      if (shift) {
        const shiftIndex = shifts.findIndex((s) => s._id === shift);
        if (shiftIndex !== -1) {
          setActiveShift(shifts[shiftIndex]);
          tabRefSet.current = true;
        }
      }

      if (application) {
        const applicantIndex = applicants.findIndex((a) => a._id === application);
        const applicant = applicants[applicantIndex];
        const profile = profiles[applicant?.user?._id];
        if (applicant && profile) {
          setViewingApplicant({ ...applicant, profile });
          tabRefSet.current = true;
        }
      }
    },
    [applicants, profiles]
  );

  return (
    <>
      <ApplicantsConfirm
        showModal={!!confirmingApplicant}
        setShowModal={setConfirmingApplicant}
        application={confirmingApplicant}
        actionApplication={actionApplicant}
        paymentMethod={paymentMethod}
      />
      <ApplicantProfile
        applicant={viewingApplicant}
        show={!!viewingApplicant}
        setShow={setViewingApplicant}
        setShowConfirmModal={(v) => (v ? setConfirmingApplicant(viewingApplicant) : setViewingApplicant(null))}
        actionApplication={actionApplicant}
        paymentMethod={paymentMethod}
        showActions={viewingApplicant?.status === "pending"}
      />
      {!isMobile ? (
        <div className={styling("container")}>
          <motion.div
            variants={sidePanelVariants}
            initial="open"
            animate={sidePanelOpen ? "open" : "closed"}
            className={styling("side-panel")}
          >
            <ShiftRow text={"All Shifters"} setActiveShift={setActiveShift} shift={null} />
            <div className={styling("shifts-wrapper")}>
              <Each
                of={shifts}
                render={({ key, ...shift }) => (
                  <ShiftRow
                    key={shift._id}
                    text={`${shift?.day_of_week} ${shift?.prettyDate} | ${shift.shiftPeriod}`}
                    subtext={`${shift?.position?.name} - Level ${shift?.position?.classification_level}`}
                    setActiveShift={setActiveShift}
                    shift={shift}
                    activeShift={activeShift}
                  />
                )}
              />
            </div>
          </motion.div>
          <div
            className={styling("table-container")}
            style={{
              width: sidePanelOpen ? "calc(100% - 360px)" : "100%",
            }}
          >
            <div className={styling("table-header")}>
              <div className={styling("header-icon")} onClick={() => setSidePanelOpen((prev) => !prev)}>
                <FontAwesomeIcon
                  icon={faArrowLeftToLine}
                  className={styling("sidepanel-icon", sidePanelOpen && "open")}
                />
              </div>
              {activeShift ? (
                <h3>{`${activeShift?.day_of_week} ${activeShift?.prettyDate} | ${activeShift.shiftPeriod}`}</h3>
              ) : (
                <h3>All Shifters</h3>
              )}
              {!paymentMethod && !paymentMethodIsFetching && (
                <div className={styling("payment-method")}>
                  <p>Please set up a payment method to accept your shifters.</p>
                  <Button link={"/business-account/billing-and-payment"} theme="secondary">
                    Set up payment method
                  </Button>
                </div>
              )}
            </div>
            <div className={styling("table")}>
              <Table
                columns={columns}
                onRowDoubleClick={({ row }) => {
                  setViewingApplicant(row);
                }}
                loading={shiftsIsFetching || applicantsIsFetching || profilesIsFetching}
                rows={applicants.map((applicant) => {
                  const profile = profiles[applicant?.user?._id];
                  return {
                    ...applicant,
                    profile,
                    id: applicant._id,
                    shifter: `${applicant?.user?.first_name} ${applicant?.user?.last_name}`,
                    rating: profile?.rating,
                    shift: applicant?.shift,
                    start_time: applicant?.shift?.start_time,
                    age: applicant.user?.date_of_birth,
                    applied: applicant.createdAt,
                    actions: "actions",
                  };
                })}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={styling("mobile-container")}>
          {applicants.length ? (
            <Each
              of={applicants}
              render={(applicant) => {
                const profile = profiles[applicant?.user?._id];
                return (
                  <ApplicantCard
                    key={applicant._id}
                    applicant={applicant}
                    profile={profile}
                    actionApplication={actionApplicant}
                    showButtons={Boolean(paymentMethod)}
                    onClick={() => setViewingApplicant({ ...applicant, profile })}
                    setShowConfirmModal={setConfirmingApplicant}
                  />
                );
              }}
            />
          ) : (
            <div className={styling("no-applicants-mobile")}>
              <div className={styles["no-heading"]}>
                <h3>Shift applicants</h3>
                <p>You have no upcoming shift applications. Hold on tight!</p>
              </div>
              <div className={styles["no-image"]}>
                <img src={Illustration} alt={"No upcoming shifts"} />
              </div>
              <div className={styles["no-button"]}>
                <Button link={"/"} theme={"primary"}>
                  View Upcoming Shifts
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default withDisplay(ShiftlyApplicants);
const ShiftRow = ({ activeShift, text, subtext, shift, setActiveShift }) => {
  const styling = useStyling(styles);
  return (
    <div
      className={styling("shift-row", shift && activeShift?._id === shift?._id && "active-row")}
      onClick={() => setActiveShift(shift)}
    >
      <div className={styling("shift-row-wrapper")}>
        <p className={styling("shift-date")}>{text}</p>
        <p className={styling("shift-position")}>{subtext}</p>
      </div>
    </div>
  );
};
