import { Calendar } from "src/components/temp/Calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./SchedulerHeader.module.css";
import clsx from "clsx";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState, useMemo, useCallback } from "react";
import { motion } from "framer-motion";
import { throttle } from "lodash";
import { useScheduler } from "src/contexts/Scheduler";
import { useNavigate } from "react-router-dom";
import { faNoteMedical, faCalendar, faRefresh } from "@fortawesome/pro-regular-svg-icons";
import SchedulerTemplates from "../calendar/templates/SchedulerTemplates";
import useShiftManager from "src/hooks/useShiftManager";

const getPeriodText = (period = []) => {
  const start = period[0]?.toLocaleDateString("en-AU", {
    month: "short",
    day: "numeric",
  });
  const end = period[period.length - 1]?.toLocaleDateString("en-AU", {
    month: "short",
    day: "numeric",
  });
  return `${start} - ${end}`;
};

const SchedulerHeader = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [showSchedulerTemplates, setShowSchedulerTemplates] = useState(false);
  const navigate = useNavigate();
  const { deleteShifts } = useShiftManager();

  const {
    periods = {},
    fromDate,
    setFromDate,
    direction,
    setDirection,
    duration,
    shifts,
    internalShifts,
    applications,
    setIsLoading,
    setMultiSelect,
    multiSelect,
  } = useScheduler();

  useEffect(() => {
    setShowCalendar(false);
  }, [fromDate]);

  const handleSetDirection = throttle((newDirection) => {
    setIsLoading(true);
    setDirection(newDirection);
  }, 500);

  const publishedShifts = useMemo(() => shifts.filter((shift) => shift.status === "published"), [shifts]);
  const confirmedShifts = useMemo(() => shifts.filter((shift) => shift.status === "confirmed"), [shifts]);

  const applicationsCount = useMemo(() => {
    return applications.reduce((acc, app) => {
      if (confirmedShifts.some((shift) => shift._id === app.shift)) {
        return acc;
      }
      acc++;
      return acc;
    }, 0);
  }, [applications, confirmedShifts]);

  const handleClearSchedule = useCallback(() => {
    deleteShifts([...shifts, ...internalShifts]);
  }, [shifts, internalShifts, deleteShifts]);

  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["controls"]}>
          <div className={clsx(styles["control"], styles["control-left"])} onClick={() => handleSetDirection("100%")}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
          <div className={clsx(styles["control"], styles["control-date"])}>
            <div className={styles["periods"]}>
              <motion.p
                animate={{ x: direction === "100%" ? direction : 0 }}
                transition={{ duration }}
                className={styles["prev-period"]}
              >
                {getPeriodText(periods.prev)}
              </motion.p>
              <motion.p animate={{ x: direction }} transition={{ duration }} onClick={() => setShowCalendar(true)}>
                {getPeriodText(periods.current)}
              </motion.p>
              <motion.p
                animate={{ x: direction === "-100%" ? direction : 0 }}
                transition={{ duration }}
                className={styles["next-period"]}
              >
                {getPeriodText(periods.next)}
              </motion.p>
            </div>
            <div className={clsx(styles["calendar"], showCalendar && styles["visible"])}>
              {showCalendar && <Calendar value={fromDate} setValue={setFromDate} range={false} />}
            </div>
          </div>
          <div className={clsx(styles["control"], styles["control-right"])} onClick={() => handleSetDirection("-100%")}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
        <div className={styles["action-buttons"]}>
          <div
            className={clsx(styles["action-btn"], styles["select-btn"], multiSelect && styles["multi-select-on"])}
            onClick={() => setMultiSelect((prev) => !prev)}
          >
            <FontAwesomeIcon icon={faNoteMedical} />
            <p>Select multiple shifts</p>
          </div>
          <div
            className={clsx(
              styles["action-btn"],
              styles["select-btn"],
              styles["scheduler-templates"],
              showSchedulerTemplates && styles["open"]
            )}
          >
            <div
              className={styles["click-wrapper"]}
              onClick={(e) => {
                setShowSchedulerTemplates((prev) => !prev);
              }}
            ></div>
            <FontAwesomeIcon icon={faCalendar} />
            <p>Schedule Templates</p>
            <SchedulerTemplates open={showSchedulerTemplates} setOpen={setShowSchedulerTemplates} />
          </div>
          <div
            className={clsx(styles["action-btn"], styles["select-btn"], multiSelect && styles["multi-select-on"])}
            onClick={handleClearSchedule}
          >
            <FontAwesomeIcon icon={faRefresh} />
            <p>Clear Schedule</p>
          </div>
        </div>
        <div className={styles["legend"]}>
          <LegendItem colour={"var(--secondary-100)"} count={internalShifts?.length} text={"internal"} />
          <LegendItem colour={"var(--orange)"} count={publishedShifts?.length} text={"published"} />
          <LegendItem
            colour={"var(--warning-300)"}
            count={applicationsCount}
            text={"applications"}
            onClick={() => navigate("/people")}
          />
          <LegendItem colour={"var(--secondary-900)"} count={confirmedShifts?.length} text={"confirmed"} />
        </div>
      </div>
    </>
  );
};
export default SchedulerHeader;
const LegendItem = ({ colour, text, count, onClick = () => {} }) => (
  <div className={styles["legend-item"]} onClick={onClick}>
    <div className={styles["legend-icon"]} style={{ background: colour }}></div>
    <p>
      <span>{count}</span> {text}
    </p>
  </div>
);
