import { useFetch, LoadingSpinner, useSearchParams } from "shiftly-ui";
import useBusiness from "src/hooks/useBusiness";
import useIntegration from "src/hooks/useIntegration";
import XeroIntegrationBase from "./components/Xero/XeroIntegrationBase";
import { useRef } from "react";

const Xero = () => {
  const { activeBusiness } = useBusiness();
  const { activeIntegration, refetch, isLoading: fetchingIntegration } = useIntegration("xero");
  const connectedRef = useRef(false);

  const { data: link } = useFetch({
    request: {
      entity: "Integration",
      method: "buildConsentUrl",
      data: {
        type: "xero",
        business: activeBusiness?._id,
      },
      id: "Integration.BuildXeroAuthUrl",
    },
    dependency: activeBusiness?._id,
  });

  const { data, isLoading } = useFetch({
    request: {
      entity: "Integration",
      method: "getElements",
      data: {
        type: "xero",
        business: activeBusiness?._id,
      },
      id: "Integration.GetXeroElements",
    },
    dependency: activeIntegration?._id,
    options: {
      enabled: Boolean(activeIntegration),
    },
  });

  const {
    post: createIntegration,
    isLoading: initIsLoading,
    refresh,
  } = useFetch({
    options: {
      onSuccess: () => {
        refetch();
        refresh("Integration.GetXeroElements");
      },
    },
  });

  useSearchParams(
    ["code", "state"],
    ({ code, state }) => {
      if (code && !fetchingIntegration && !connectedRef.current && activeBusiness?._id) {
        connectedRef.current = true;
        createIntegration({
          entity: "Integration",
          method: "init",
          data: {
            business: activeBusiness?._id,
            type: "xero",
            state,
            urlPath: window.location.href,
          },
        });
      }
    },
    [activeIntegration, activeBusiness, fetchingIntegration]
  );

  return fetchingIntegration ? (
    <LoadingSpinner />
  ) : (
    <XeroIntegrationBase
      initIsLoading={initIsLoading}
      isLoading={isLoading}
      connectionLink={link}
      activeIntegration={activeIntegration}
      xeroOrganisations={data?.organisations ?? []}
    ></XeroIntegrationBase>
  );
};

export default Xero;
