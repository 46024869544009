import { useFetch } from "shiftly-ui";
import useShiftlyLocation from "./useShiftlyLocation";

const useInternalStaff = () => {
  const { activeLocation } = useShiftlyLocation();

  const { data: internalStaff } = useFetch({
    request: {
      entity: "InternalStaff",
      method: "get",
      id: "InternalStaff.GetAllInternalStaffForLocation",
      criteria: {
        location_id: activeLocation?._id,
      },
    },
    dependency: activeLocation?._id,
  });

  return { internalStaff };
};

export default useInternalStaff;
