import { Each, getDayOfWeek, getMonthShortName } from "shiftly-ui";
import clsx from "clsx";
import styles from "./SchedulerCalendarNav.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { motion } from "framer-motion";
import { throttle } from "lodash";
import { useScheduler } from "src/contexts/Scheduler";
import { useCallback } from "react";

const SchedulerCalendarNav = (props) => {
  const { periods, direction, setDirection, duration, setIsLoading, setDuration, handleNextPeriod, handlePrevPeriod } =
    useScheduler();

  const handleSetDirection = throttle((newDirection) => {
    setIsLoading(true);
    setDirection(newDirection);
  }, 500);

  const onComplete = useCallback(() => {
    setDuration(0);
    setDirection(0);

    if (direction === "100%") {
      handlePrevPeriod();
    } else if (direction === "-100%") {
      handleNextPeriod();
    }

    setTimeout(() => {
      setDuration(0.5);
      setIsLoading(false);
    }, 100);
  }, [direction, handleNextPeriod, handlePrevPeriod, setDirection, setIsLoading, setDuration]);

  return (
    <div className={styles["container"]}>
      <div className={clsx(styles["control"], styles["control-left"])} onClick={() => handleSetDirection("100%")}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
      <div className={styles["dates"]}>
        <motion.div
          animate={{ x: direction === "100%" ? direction : 0 }}
          transition={{ duration }}
          className={clsx(styles["date"], styles["prev"])}
        >
          <DateFields list={periods.prev} />
        </motion.div>
        <motion.div
          onAnimationComplete={onComplete}
          animate={{ x: direction }}
          transition={{ duration }}
          className={clsx(styles["date"], styles["current"])}
        >
          <DateFields list={periods.current} />
        </motion.div>
        <motion.div
          animate={{ x: direction === "-100%" ? direction : 0 }}
          transition={{ duration }}
          className={clsx(styles["date"], styles["next"])}
        >
          <DateFields list={periods.next} />
        </motion.div>
      </div>
      <div className={clsx(styles["control"], styles["control-right"])} onClick={() => handleSetDirection("-100%")}>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </div>
  );
};
const DateFields = ({ list }) => (
  <>
    <div className={styles["header-column"]}></div>
    <Each
      of={list.map((date) => {
        return {
          dayOfWeek: getDayOfWeek(date),
          dayOfMonth: date.getDate(),
          month: getMonthShortName(date),
          year: date.getFullYear(),
          date,
        };
      })}
      render={(date, index) => (
        <div key={index} className={styles["date-section"]}>
          <p className={styles["date-text"]}>
            {date.dayOfWeek} {date.dayOfMonth}
          </p>
        </div>
      )}
    />
  </>
);
export default SchedulerCalendarNav;
