import { useFetch, Each, useStyling, useSearchParams, Button, useSocket } from "shiftly-ui";
import styles from "./MessagesCenter.module.css";
import MessagesUpcomingShifters from "./MessagesUpcomingShifters";
import Illustration from "shiftly-ui/assets/svg/Illustrations/Messages.svg";
import MessagesChat from "./MessagesChat";
import MessagesMessageBar from "./MessagesMessageBar";
import { useEffect, useCallback, useRef, useState } from "react";
import useMessagesCriteria from "src/pages/app/messages/hooks/useMessagesCriteria";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import MessagesShiftTray from "./MessagesShiftTray";


const MessagesCenter = ({ activeConversation, setActiveConversation = () => {} }) => {
  const [toField, setToField] = useState("");
  const [showInputDropdown, setShowInputDropdown] = useState(false);
  const [filteredShifters, setFilteredShifters] = useState([]);
  const [showShiftTray, setShowShiftTray] = useState(false);
  const styling = useStyling(styles);
  const { activeLocation } = useShiftlyLocation();
  const { sendEvent } = useSocket();
  const criteria = useMessagesCriteria(activeConversation);
  const scrollRef = useRef();
  const inputRef = useRef();
  const messageSentRef = useRef();

  const {
    post: createNewConversation,
    refresh,
    isFetching,
  } = useFetch({
    options: {
      onSuccess: (data = []) => {
        refresh("Conversation.GetExistingConversations");
        const user = relevantShifters.find((shifter) => shifter._id === data[0]?.user);
        data?.[0] && setActiveConversation({ ...data[0], user });
      },
    },
  });

  const { post: sendMessage, updateCache } = useFetch({
    options: {
      onMutate: ({ data }) => {
        updateCache("Message.GetMessagesWithAccess", (oldData = []) => [...oldData, data]);
      },
      onSuccess: ([mes] = []) => {
        sendEvent("send_message", mes);
        refresh("Message.GetMessagesWithAccess");
      },
    },
  });

  const { data: conversations } = useFetch({
    request: {
      entity: "Conversation",
      method: "getExistingConversations",
      criteria,
      id: "Conversation.GetExistingConversations",
    },
    dependency: criteria,
  });

  useSearchParams(
    ["user", "shift"],
    ({ user, shift }) => {
      if (user && !isFetching) {
        const conversation = conversations.find((conversation) => conversation.user?._id === user);
        conversation && setActiveConversation(conversation);
        if (shift && !messageSentRef.current && conversation) {
          const newMessage = {
            content: shift,
            conversation: conversation._id,
            user: conversation.user?._id || conversation.user,
            location: activeLocation?._id,
            for_id: conversation.user?._id || conversation.user,
            unread: true,
            sent_date: new Date(),
            type: "shift",
          };
          sendMessage({
            entity: "Message",
            data: newMessage,
            method: "create",
          });
          messageSentRef.current = true;
        }
      }
    },
    [conversations, isFetching, activeLocation]
  );

  const { data: relevantShifters } = useFetch({
    request: {
      entity: "Conversation",
      method: "getRelevantShifters",
      data: { location_id: activeLocation?._id },
      id: "Conversation.GetRelevantShifters",
    },
    dependency: activeLocation?._id,
    options: {
      onSuccess: (data) => {
        setFilteredShifters(data);
      },
    },
  });

  useEffect(() => {
    setFilteredShifters(
      relevantShifters.filter(
        (shifter) =>
          shifter.first_name?.toLowerCase().includes(toField?.toLowerCase()) ||
          shifter.last_name?.toLowerCase().includes(toField?.toLowerCase())
      )
    );
  }, [toField, relevantShifters]);

  const scrollToBottom = useCallback(() => {
    if (scrollRef.current) {
      setTimeout(() => {
        if (!scrollRef.current || scrollRef.current === null) return;
        scrollRef.current.scrollTop = scrollRef.current?.scrollHeight;
      }, 300);
    }
  }, [scrollRef]);

  useEffect(() => {
    scrollToBottom();
    if (!activeConversation) return;
    if (activeConversation === "temp") {
      setToField("");
      inputRef.current?.focus();
      setActiveConversation();
    } else {
      setToField(activeConversation?.user?.first_name + " " + activeConversation?.user?.last_name);
    }
  }, [scrollToBottom, activeConversation, setActiveConversation]);

  const handleInputChange = useCallback(
    (e) => {
      const value = e.target.value;
      setActiveConversation();
      setToField(value);
      if (value.length > 0) {
        setShowInputDropdown(true);
      } else {
        setShowInputDropdown(false);
      }
    },
    [setActiveConversation, setToField, setShowInputDropdown]
  );

  const handleSelectChat = useCallback(
    (shifter) => {
      setShowInputDropdown(false);
      const conversation = conversations.find((conversation) => {
        return conversation.user?._id === shifter._id;
      });
      if (conversation) {
        setActiveConversation(conversation);
      } else {
        createNewConversation({
          entity: "Conversation",
          method: "create",
          data: {
            user: shifter._id,
            location: activeLocation?._id,
            start_date: new Date(),
            last_sent: new Date(),
          },
        });
      }
    },
    [conversations, setActiveConversation, setShowInputDropdown, createNewConversation, activeLocation]
  );
  
  return (
    <div className={styling("container")}>
      <div className={styling("heading")}>
        <p>To:</p>
        <input value={toField} onChange={handleInputChange} ref={inputRef} onFocus={() => setShowInputDropdown(true)} />
        {showInputDropdown && (
          <div className={styling("input-overlay")} onClick={() => setShowInputDropdown(false)}></div>
        )}
        <div className={styling("input-dropdown", showInputDropdown && "visible")}>
          <Each
            of={filteredShifters}
            render={(shifter, index) => (
              <div className={styling("relevant-shifter")} key={index} onClick={() => handleSelectChat(shifter)}>
                <div className={styling("dropdown-initial")}>
                  {!shifter.profile?.profile_picture && shifter.first_name.charAt(0)}
                  {!shifter.profile?.profile_picture && shifter.last_name.charAt(0)}
                  {shifter.profile?.profile_picture && <img src={shifter.profile?.profile_picture} alt="Profile" />}
                </div>
                <div className={styling("dropdown-details")}>
                  <p>{shifter.first_name + " " + shifter.last_name}</p>
                </div>
              </div>
            )}
          />
        </div>
        {activeConversation && (
          <div className={styling("shift-tray-btn")}>
            <Button theme={"secondary-outline"} onClick={() => setShowShiftTray((prev) => !prev)}>
              {showShiftTray ? "Hide" : "View"} Upcoming Shifts
            </Button>
          </div>
        )}
      </div>
      <div className={styling("content")}>
        <MessagesShiftTray
          sendMessage={sendMessage}
          activeConversation={activeConversation}
          open={showShiftTray}
          setOpen={setShowShiftTray}
        />
        <div className={styling("messages")} ref={scrollRef}>
          {activeConversation ? (
            <MessagesChat activeConversation={activeConversation} scrollToBottom={scrollToBottom} />
          ) : (
            <NoMessageSelectedCover />
          )}
        </div>
        <div className={styling("interaction-bar")}>
          {!activeConversation ? (
            <MessagesUpcomingShifters
              activeConversation={activeConversation}
              setActiveConversation={setActiveConversation}
            />
          ) : (
            <MessagesMessageBar activeConversation={activeConversation} sendMessage={sendMessage} />
          )}
        </div>
      </div>
    </div>
  );
};
const NoMessageSelectedCover = () => {
  const styling = useStyling(styles);
  return (
    <div className={styling("no-message-container")}>
      <div className={styling("no-message-image")}>
        <img src={Illustration} alt="No Messages" />
      </div>
      <div className={styling("no-message-text")}>
        <h3>Looks like your chat is playing hide and seek!</h3>
        <h5>Tap on a shifter to find it.</h5>
      </div>
    </div>
  );
};
export default MessagesCenter;