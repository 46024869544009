import { Each, Button, useUI } from "shiftly-ui";
import styles from "./BusinessNavLaptopMode.module.css";
import { faSignalStream } from "@fortawesome/pro-regular-svg-icons";
import BusinessNavItem from "./BusinessNavItem";
import logo from "shiftly-ui/assets/logos/icon.svg";
import BusinessNavDropDown from "./BusinessNavDropDown";
import BusinessNotificationCentre from "./BusinessNotificationCentre";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import BusinessLocationSelector from "./BusinessLocationSelector";
import usePositions from "src/hooks/usePositions";
import clsx from "clsx";

const BusinessNavLaptopMode = ({ links, locationFilter, setlocationFilter, showLocationSelector }) => {
  const [activeNav, setActiveNav] = useState(0);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const { showNewShiftModal, setNewShiftModalDefaults } = useUI();

  const { availablePositions } = usePositions();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const path = window.location.pathname;
    setActiveNav(links.findIndex((link) => link.path === path));
  }, [links, location]);

  return (
    <>
      <div className={styles["container"]}>
        <div className={styles["logo-container"]} onClick={() => navigate("/shifts")}>
          <img src={logo} alt="Shiftly Logo" className={styles["logo"]} />
        </div>
        <div className={clsx(styles["location-selector"], !showLocationSelector && styles["hidden"])}>
          <BusinessLocationSelector
            locationFilter={locationFilter}
            setlocationFilter={setlocationFilter}
            showLocationSelector={showLocationSelector}
          />
        </div>
        <div className={styles["navbar-container"]}>
          <Each
            of={links}
            render={({ key, ...link }, index) => (
              <BusinessNavItem {...link} key={key} index={index} activeIndex={activeNav} setActiveNav={setActiveNav} />
            )}
          />
        </div>
        {showLocationSelector && (
          <div className={styles["account-container"]}>
            <BusinessNotificationCentre />
            <Button
              className={styles["emergency-shift"]}
              onClick={() => {
                setNewShiftModalDefaults({
                  shiftDate: new Date().toLocaleDateString(),
                });
                showNewShiftModal(true);
              }}
              icon={faSignalStream}
              disabled={availablePositions.length === 0}
            >
              Create New Shift
            </Button>
          </div>
        )}
      </div>
      <BusinessNavDropDown open={openSubMenu} setOpen={setOpenSubMenu} />
    </>
  );
};
export default BusinessNavLaptopMode;
