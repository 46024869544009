import { useCallback } from "react";
import styles from "./PricingCard.module.css";
import { useStyling, Each, Button, useFetch, useAlerts } from "shiftly-ui";
import useBusiness from "src/hooks/useBusiness";
import useSubscriptions from "src/hooks/useSubscriptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const PricingCard = ({ title = "", description = "", prices = [], highlight = "", active = false }) => {
  const styling = useStyling(styles);
  const { activeBusiness, setActiveBusiness } = useBusiness();
  const { current } = useSubscriptions();

  const navigate = useNavigate();

  const { confirm } = useAlerts();

  const {
    data: [paymentMethod],
    isFetching,
  } = useFetch({
    request: {
      entity: "PaymentMethod",
      method: "get",
      criteria: {
        is_default: true,
      },
      id: "PaymentMethod.GetDefaultPaymentMethod",
    },
  });

  const { post: upgradePlan, isLoading } = useFetch({
    options: {
      onSuccess: ([data] = []) => {
        data && setActiveBusiness(data);
      },
    },
  });

  const handleChangePlan = useCallback(async () => {
    if (!paymentMethod) {
      navigate("/business-account/billing-and-payment");
      return;
    }

    if (
      !(await confirm({
        label: "Change Plan",
        text: `Are you sure you want to change to the ${title} plan? Your next invoice will reflect a prorated charge for the time spent on your current plan, plus the cost of the  ${title} for the remaining period. `,
        confirmText: "Change",
        cancelText: "Cancel",
      }))
    ) {
      return;
    }
    upgradePlan({
      entity: "Business",
      method: "updateSubscription",
      data: {
        prices,
        subscription: current.id,
        business: activeBusiness?._id,
      },
    });
  }, [upgradePlan, prices, activeBusiness, current, confirm, title, paymentMethod, navigate]);

  return (
    <div className={styling("container")}>
      {active && (
        <div className={styling("active-plan")}>
          <p>You're on this plan</p>
        </div>
      )}
      <h1 className={styling("title")}>{title}</h1>
      <p className={styling("description")}>{description}</p>
      <p className={styling("highlight")}>{highlight}</p>
      <div className={styling("prices")}>
        <Each of={prices} render={({ key, amount, unit }) => <p key={key}>{`$${amount} per ${unit}`}</p>} />
      </div>
      <div className={styling("button")}>
        <Button
          size={"fw"}
          disabled={active || isLoading || isFetching}
          theme={active ? "secondary-outline" : "secondary"}
          onClick={handleChangePlan}
        >
          {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : active ? "Current Plan" : "Change Plan"}
        </Button>
      </div>
    </div>
  );
};

export default PricingCard;
