import { withDisplay, Link } from "shiftly-ui";
import React, { useLayoutEffect } from "react";
import styles from "./Scheduler.module.css";
import SchedulerHeader from "./header/SchedulerHeader";
import SchedulerCalendarGrid from "./calendar/SchedulerCalendarGrid";
import SchedulerCalendarNav from "./calendar/SchedulerCalendarNav";
import { useNavigate } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import SchedulerContextMenu from "./SchedulerContextMenu";
import useContextMenu from "./hooks/useContextMenu";
const Scheduler = React.memo(({ isMobile, isLaptop }) => {
  const navigate = useNavigate();
  const { handleRightClick, handleTouchEnd, handleTouchStart, ...contextMenu } = useContextMenu();
  // Navigate to mobile shifts if isMobile is true
  useLayoutEffect(() => {
    if (isMobile) {
      navigate("/mobile-shifts");
    }
  }, [isMobile, navigate]);
  
  return (
    <DndProvider backend={isLaptop ? HTML5Backend : TouchBackend}>
      <SchedulerContextMenu {...contextMenu} />
      <div
        className={styles["container"]}
        onContextMenu={handleRightClick}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <SchedulerHeader />
        <div className={styles["calendar"]}>
          <SchedulerCalendarNav />
          <SchedulerCalendarGrid />
          <div className={styles["new-position"]}>
            <p className={styles["new-pos-title"]}>New Position</p>
            <div className={styles["new-pos"]}>
              <Link to={"/positions?mode=new"}> + Add New Position</Link>
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
});
export default withDisplay(Scheduler);