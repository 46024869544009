import { Input, useFetch, FormV2, Button, RadioButtons } from "shiftly-ui";
import styles from "./BusinessAccount.module.css";
import useBusiness from "src/hooks/useBusiness";
import StandardLayout from "../../settings/components/StandardLayout";
import NewBusinessRadioButtonCard from "../new_business_setup/HQNewBusinessRadioButtonCard";
import { faBurger, faForkKnife, faHotel, faMartiniGlass, faSave } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";

const iconMap = {
  "Restaurant or Cafe": faForkKnife,
  "Fast Food": faBurger,
  "Bar or Nightclub": faMartiniGlass,
  "Other Hospitality": faHotel,
};

const BusinessAccountBusinessDetails = () => {
  const { activeBusiness, setActiveBusiness } = useBusiness();
  const { data: industries } = useFetch({ request: { entity: "Industry" } });
  const {
    post: saveBusiness,
    isLoading,
    isError,
  } = useFetch({
    options: {
      onSuccess: ([newBusiness]) => {
        setActiveBusiness({
          ...newBusiness,
          industries:
            newBusiness?.industries?.map((i) => {
              const industryIndex = industries.findIndex((ind) => ind._id === i);
              return industries[industryIndex];
            }) || [],
        });
      },
    },
  });
  const [data, setData] = useState({});

  useEffect(() => {
    setData({ ...activeBusiness, industries: activeBusiness?.industries?.map((i) => i?._id) || [] });
  }, [activeBusiness]);

  return (
    <StandardLayout
      heading={
        <h1>
          <span>Business</span> Details
        </h1>
      }
      breadcrumb={"Business Settings /"}
      returnLink={"/business-account"}
      size={"medium-large"}
    >
      <div className={styles["home-container"]}>
        <div className={styles["home-content"]}>
          <div className={styles["home-content-block"]}>
            <FormV2
              onSubmit={async (data) => {
                return await saveBusiness({
                  entity: "Business",
                  criteria: { _id: activeBusiness?._id },
                  data,
                  method: "update",
                });
              }}
              initial={activeBusiness}
              data={data}
              setData={setData}
            >
              <Input label={"Business Name"} name={"name"} placeholder={"Business Name"} />
              <Input
                label={"Australian Business Number (ABN)"}
                name={"ABN"}
                placeholder={"Australian Business Number"}
              />
              <RadioButtons
                required
                align="center"
                multiselect
                options={industries.map((industry) => ({
                  label: industry?.name,
                  value: industry?._id,
                  content: (
                    <NewBusinessRadioButtonCard
                      label={industry?.name}
                      award={industry?.award_code}
                      icon={iconMap[industry?.name]}
                      active={!!data?.industries?.find((i) => i === industry?._id?.toString())}
                    />
                  ),
                }))}
                type="cards"
                name="industries"
              />
              <div className={styles["footer"]}>
                <Button
                  error={isError}
                  loading={isLoading}
                  theme={"primary"}
                  type="submit"
                  icon={faSave}
                  className={styles["save-button"]}
                >
                  Save
                </Button>
              </div>
            </FormV2>
          </div>
        </div>
      </div>
    </StandardLayout>
  );
};
export default BusinessAccountBusinessDetails;
