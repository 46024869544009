import styles from "./PricingLayout.module.css";
import { useStyling, Each } from "shiftly-ui";
import StandardLayout from "src/pages/settings/components/StandardLayout";
import PricingCard from "./PricingCard";

const PricingLayout = ({ cards = [], name }) => {
  const styling = useStyling(styles);
  return (
    <StandardLayout
      heading={
        <h1>
          <span>{name}</span> pricing
        </h1>
      }
      size="large"
      breadcrumb={"Business Profile / Billing & Payments / Pricing Plans /"}
      returnLink={"/business-account/billing-and-payment/pricing"}
    >
      <div className={styling("container")}>
        <Each of={cards} render={({ key, ...card }) => <PricingCard key={key} {...card} />} />
      </div>
    </StandardLayout>
  );
};

export default PricingLayout;
