import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import styles from "./InternalStaffTable.module.css";
import { useStyling, useFetch, Input, Table } from "shiftly-ui";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { useCallback, useMemo, useState } from "react";
import ActionsDropdown from "./ActionsDropdown";
import useBusiness from "src/hooks/useBusiness";
import mongoose from "mongoose";

const InternalStaffTable = () => {
  const styling = useStyling(styles);
  const { activeBusiness } = useBusiness();
  const { activeLocation } = useShiftlyLocation();
  const [searchText, setSearchText] = useState("");

  const { data: internalStaff, isLoading } = useFetch({
    request: {
      entity: "InternalStaff",
      method: "get",
      id: "User.GetInternalStaff",
      criteria: {
        location: activeLocation?._id,
      },
      options: {
        GetOnboardingStatus: true,
      },
    },
    dependency: activeLocation?._id,
  });

  const {
    data: [integration],
  } = useFetch({
    request: {
      id: "Integration.GetIntegrationForEmployeeOnboarding",
      entity: "Integration",
      method: "get",
      criteria: {
        business: activeBusiness?._id,
        type: "xero",
        status: "active",
      },
    },
    dependency: activeBusiness?._id,
  });

  const { post, isLoading: loadingAction } = useFetch({});

  const sendSyncRequest = useCallback(
    ({ onboarding_status, _id }) => {
      if (onboarding_status === "unsynced") {
        const employee_onboarding = new mongoose.Types.ObjectId();
        post([
          {
            entity: "Employment",
            method: "update",
            criteria: {
              internallStaff: _id,
            },
            data: {
              employee_onboarding,
            },
          },
          {
            entity: "TaxDeclaration",
            method: "update",
            criteria: {
              internallStaff: _id,
            },
            data: {
              employee_onboarding,
            },
          },
          {
            entity: "EmployeeOnboard",
            method: "create",
            data: {
              status: "pending",
              internalStaff: _id,
              integration: integration._id,
              location: activeLocation?._id,
            },
          },
        ]);
      }
    },
    [activeLocation?._id, post, integration]
  );

  const sendReminder = useCallback((row) => {
    console.log("reminder", row);
  }, []);

  const columns = useMemo(
    () => [
      { flex: 1, field: "shifter", headerName: "Shifter", sortable: true, align: "left" },
      { flex: 1, field: "email", headerName: "Email", sortable: true, align: "left" },
      { flex: 1, field: "phone", headerName: "Phone", sortable: true, align: "left" },
      { flex: 1, field: "stream", headerName: "Stream", sortable: true, align: "left" },
      {
        flex: 1,
        field: "level",
        headerName: "Level",
        sortable: true,
        align: "center",
        render: (row) => (row.level ? "Level " + row.level : "-"),
      },
      {
        flex: 1,
        field: "hourly_rate",
        headerName: "Hourly Rate",
        sortable: true,
        align: "center",
        render: (row) =>
          row.hourly_rate
            ? new Intl.NumberFormat("en-AU", { style: "currency", currency: "AUD" }).format(row.hourly_rate)
            : "-",
      },
      {
        width: 100,
        field: "actions",
        headerName: "",
        sortable: false,
        align: "center",
        render: (row) => {
          const actions = [];

          if (row.onboarding_status === "pending") {
            actions.push({
              label: "Send Reminder",
              action: () => sendReminder(row),
            });
          }
          return (
            <div className={styling("actions")}>
              <ActionsDropdown actions={actions} />
            </div>
          );
        },
      },
      {
        width: 120,
        field: "synced",
        headerName: "Xero Sync",
        sortable: false,
        align: "center",
        render: (row) => {
          switch (row.onboarding_status) {
            case "pending":
              return <span className={styling("sync", "pending")}></span>;

            case "approved":
              return <span className={styling("sync", "approved")}></span>;

            default:
              return (
                <span className={styling("unsynced")} onClick={() => sendSyncRequest(row)}>
                  Sync with Xero
                </span>
              );
          }
        },
      },
    ],
    [sendReminder, styling, sendSyncRequest]
  );

  const filteredInternalStaff = useMemo(() => {
    if (!internalStaff) return [];
    return internalStaff.filter(
      (staff) =>
        staff.shifter.toLowerCase().includes(searchText.toLowerCase()) ||
        staff.email.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [internalStaff, searchText]);

  return (
    <div className={styling("container")}>
      <div className={styling("search-container")}>
        <div className={styling("search-input")}>
          <Input
            label={"Search internal staff member"}
            setValue={setSearchText}
            value={searchText}
            placeholder={"Search by name or email"}
            icon={{ icon: faMagnifyingGlass, side: "right" }}
          />
        </div>
      </div>
      <div className={styling("table")}>
        <Table
          getRowId={(r) => r._id}
          columns={columns}
          onRowDoubleClick={({ row }) => {}}
          loading={isLoading || loadingAction}
          rows={filteredInternalStaff}
          sx={{
            "& .MuiDataGrid-root": {
              border: "none !important",
            },
          }}
        />
      </div>
    </div>
  );
};

export default InternalStaffTable;
