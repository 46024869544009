import { Each, Link, buildAwardSummaryLink, useStyling, Checkbox } from "shiftly-ui";
import styles from "./NewPosition.module.css";
import barista from "./assets/barista.svg";

const NewPositionPreview = ({ data = {}, qualifications = [] }) => {
  const styling = useStyling(styles);

  return (
    <div className={styling("container")}>
      <div className={styling("heading")}>
        <h1>
          Here is a <span>preview</span> of your position.
        </h1>
      </div>
      <div className={styling("show-position")}>
        <Checkbox name={"is_active"} label={"Would you like this position to be visible on the scheduler?"} />
      </div>
      <div className={styling("content", "short-content")}>
        <div className={styling("card")}>
          <div className={styling("pos-heading")}>
            <div className={styling("illustration")}>
              <img src={barista} alt="barista" />
            </div>
            <div className={styling("name")}>{data?.name}</div>
          </div>
          <div className={styling("description")}>{data.description}</div>
          {qualifications.length > 0 && (
            <div className={styling("qualifications")}>
              <h3>Qualifications</h3>
              <div className={styling("qual-grid")}>
                <Each
                  of={qualifications}
                  render={(qualification, index) => <div key={index}>{qualification.description}</div>}
                />
              </div>
            </div>
          )}
          <div className={styling("classification")}>
            <Link to={buildAwardSummaryLink(data?.award_code)}>{data?.award_code}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewPositionPreview;
