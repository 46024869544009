import { useNavigate } from "react-router-dom";
import styles from "./PricingHome.module.css";
import { useStyling, Each } from "shiftly-ui";

const modules = [
  {
    title: "Shiftly Scheduler",
    description:
      "Effortlessly plan and manage your team’s roster with the Shiftly Scheduler. Designed for small businesses, it’s the intuitive online rostering solution that makes scheduling shifts a breeze.",
    features: [
      "Drag-and-drop shift scheduling.",
      "Export rosters to PDF or share online.",
      "Automated shift reminders.",
      "Employee communication and messaging.",
      "24/7 customer support.",
    ],
    link: "/business-account/billing-and-payment/pricing/scheduler",
  },
  {
    title: "Shiftly Onboarding",
    description:
      "Say goodbye to paperwork with Shiftly Onboarding, the seamless solution for bringing new staff on board. Fully integrated with Xero, this module streamlines the entire process, saving you time and hassle.",
    features: [
      "Paperless employee onboarding.",
      "Direct integration with Xero.",
      "Collect TFNs, super details and bank information securely",
      "Automatic onboarding reminders and notifications.",
      "STP2.0 compliant.",
    ],
    link: "/business-account/billing-and-payment/pricing/onboarding",
  },
  {
    title: "Shiftly Ondemand",
    description:
      "Shiftly Ondemand connects businesses with skilled workers to fill shifts quickly and effortlessly. Whether it’s covering a last-minute sick call or managing a busy period, our platform ensures you get the right person for the job, every time.",
    features: [
      "Access a pool of experienced workers.",
      "We handle payroll, super and tax for you.",
      "Instantly match and approve shifts with qualified staff.",
      "Award-compliant pay rates tailored to each worker.",
      "Built-in messaging for seamless communication",
    ],
    link: "/business-account/billing-and-payment/pricing/ondemand",
  },
];

const PricingHome = () => {
  const styling = useStyling(styles);
  return (
    <div className={styling("container")}>
      <Each of={modules} render={({ key, ...module }) => <ModuleCard key={key} {...module} />} />
    </div>
  );
};

const ModuleCard = ({ title, description, features, link }) => {
  const styling = useStyling(styles);
  const navigate = useNavigate();

  return (
    <div className={styling("card")} onClick={() => navigate(link)}>
      <h2>{title}</h2>
      <p>{description}</p>
      <ul>
        {features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
      <a href={link}>Learn more</a>
    </div>
  );
};
export default PricingHome;
