import styles from "./SchedulerTemplates.module.css";
import { useStyling, Modal, ModalLabel, FormV2, Input, TextArea, Checkbox, Button, useFetch } from "shiftly-ui";
import { useCallback } from "react";
import { useScheduler } from "src/contexts/Scheduler";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import useShiftManager from "src/hooks/useShiftManager";

const SchedulerTemplatesSave = ({ showSaveModal, setShowSaveModal }) => {
  const styling = useStyling(styles);
  const { activeLocation } = useShiftlyLocation();
  const { normaliseShift } = useShiftManager();

  const {
    shifts,
    internalShifts,
    periods: { current },
  } = useScheduler();

  const {
    post: saveSchedulerTemplate,
    isLoading,
    isError,
    refresh,
  } = useFetch({
    options: {
      onSuccess: () => {
        setShowSaveModal(false);
        refresh("ScheduleTemplate.GetAllForLocation");
      },
    },
  });

  const formatShifts = useCallback(
    (shifts = [], includeStaff = false) => {
      return shifts.map(({ _id, ...shift }) => {
        return {
          ...shift,
          ...normaliseShift(shift),
          user: includeStaff ? shift.user : null,
          position: shift.type === "shiftly" ? shift.position?._id : null,
        };
      });
    },
    [normaliseShift]
  );

  const onSubmit = useCallback(
    async (data) => {
      return await saveSchedulerTemplate({
        entity: "ScheduleTemplate",
        method: "create",
        data: {
          ...data,
          location: activeLocation?._id,
          shifts: [...formatShifts(shifts), ...formatShifts(internalShifts, data?.includeInternalStaff)],
        },
      });
    },
    [saveSchedulerTemplate, activeLocation, shifts, internalShifts, formatShifts]
  );

  const totalShifts = shifts.length + internalShifts.length;

  return (
    <Modal
      className={styling("modal")}
      showModal={showSaveModal}
      setShowModal={setShowSaveModal}
      label={
        <ModalLabel
          text={
            <h3>
              <span>Save</span> as a template
            </h3>
          }
        />
      }
    >
      <div className={styling("content")}>
        <div className={styling("title")}>
          <h5>
            Creating a template from the {totalShifts} shift{totalShifts > 1 ? "s" : ""} between{" "}
            {current[0].toLocaleDateString()} and {current[current.length - 1].toLocaleDateString()}
          </h5>
        </div>
        <FormV2 onSubmit={onSubmit}>
          <Input name={"name"} label={"Name your template"} required placeholder={"Type name here"} />
          <TextArea
            name={"description"}
            label={"Describe this template"}
            placeholder={"Write a description to help you identify this schedule template."}
          />
          <Checkbox
            name={"includeInternalStaff"}
            submitOnEnter
            my={"20px"}
            label={"Save shifts with internal staff members allocated?"}
          />
          <div className={styling("footer")}>
            <Button onClick={() => setShowSaveModal(false)} theme="secondary-outline" className={styling("btn")}>
              Cancel
            </Button>
            <Button type="submit" className={styling("btn")} disabled={isLoading} error={isError}>
              Save Template
            </Button>
          </div>
        </FormV2>
      </div>
    </Modal>
  );
};

export default SchedulerTemplatesSave;
