import { useAuth, Link, useFetch, useAlerts } from "shiftly-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./BusinessAccountPaymentMethodDisplay.module.css";
import { faCreditCard, faInstitution } from "@fortawesome/pro-duotone-svg-icons";
import clsx from "clsx";
import { faSpinner, faTrash } from "@fortawesome/pro-solid-svg-icons";
import { useCallback } from "react";
import useBusiness from "src/hooks/useBusiness";

const BusinessAccountPaymentMethodDisplay = ({
  stripe_payment_method,
  type,
  au_becs_debit = {},
  is_default,
  card = {},
}) => {
  const { user } = useAuth();
  const { activeBusiness } = useBusiness();

  const { post, refresh, isLoading } = useFetch({
    options: {
      onSuccess: (data) => {
        refresh("PaymentMethod.GetPaymentMethods");
      },
    },
  });

  const { confirm } = useAlerts();

  const handleDelete = useCallback(async () => {
    if (
      !(await confirm({
        label: "Are you sure you want to delete this payment method?",
        text: <p>Deleting a method cannot be undone.</p>,
        inverse: true,
        confirmText: "Delete",
      }))
    )
      return;

    post({
      entity: "PaymentMethod",
      method: "delete",
      criteria: {
        stripe_payment_method,
      },
    });
  }, [post, stripe_payment_method, confirm]);

  const handleMakeDefault = useCallback(() => {
    post({
      entity: "PaymentMethod",
      method: "setDefaultPaymentMethod",
      data: {
        paymentMethod: stripe_payment_method,
        business: activeBusiness?._id,
      },
    });
  }, [post, stripe_payment_method, activeBusiness]);

  return (
    <div className={clsx(styles["container"], is_default && styles["border"])}>
      {isLoading ? (
        <FontAwesomeIcon icon={faSpinner} spin className={styles["loading-spinner"]} />
      ) : (
        <div className={styles["wrapper"]}>
          <div className={styles["image"]}>
            <FontAwesomeIcon icon={type === "card" ? faCreditCard : faInstitution} />
          </div>
          {type === "card" ? (
            <div className={styles["details"]}>
              <div className={styles["heading"]}>
                <p>
                  {card.brand?.toUpperCase()} ending <strong>{card?.last4}</strong>
                </p>
              </div>
              <div className={styles["body"]}>
                <p>
                  <strong>Expiry: </strong> {card.exp_month}/{card.exp_year}
                </p>
              </div>
            </div>
          ) : (
            <div className={styles["details"]}>
              <div className={styles["heading"]}>
                <p>
                  {user?.first_name} {user?.last_name}
                </p>
              </div>
              <div className={styles["body"]}>
                <p>
                  <strong>BSB: </strong>
                  {au_becs_debit?.bsb_number}
                </p>
                <p>
                  <strong>Account Number: </strong> xxxx-{au_becs_debit?.last4}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
      {!is_default && !isLoading && (
        <>
          <Link onClick={handleMakeDefault} className={styles["default-method"]}>
            Set as default payment method
          </Link>
          <FontAwesomeIcon icon={faTrash} className={styles["trash-icon"]} onClick={handleDelete} />
        </>
      )}
    </div>
  );
};
export default BusinessAccountPaymentMethodDisplay;
