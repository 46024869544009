import { withDisplay, useGestures } from "shiftly-ui";
import styles from "./BusinessWrapper.module.css";
import { useEffect } from "react";
import useBusiness from "src/hooks/useBusiness";
import MobileLocationSelector from "./MobileLocationSelector";
import TaskList from "./task_list/TaskList";
import { useLocation } from "react-router-dom";
import RateShifter from "src/pages/app/misc/ratings/RateShifter";

const BusinessWrapper = ({ children, isMobile }) => {
  const { initBusiness } = useBusiness();
  const { pathname } = useLocation();
  const { bind } = useGestures();

  useEffect(() => {
    initBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    pathname !== "/portal" && (
      <div className={styles["container"]} {...bind()}>
        <RateShifter />
        {pathname !== "/" && isMobile && <MobileLocationSelector />}
        {!isMobile && <TaskList />}
        <div className={styles["content"]}>{children}</div>
      </div>
    )
  );
};
export default withDisplay(BusinessWrapper);
