import { useCallback, useMemo, useRef, useState } from "react";
import styles from "./NewStaffMember.module.css";
import { useStyling } from "shiftly-ui";
import mongoose from "mongoose";

import {
  FormV2,
  FormFlex,
  Input,
  responsibilities,
  Button,
  useFetch,
  Dropdown,
  RadioButtons,
  LoadingSpinner,
  Checkbox,
} from "shiftly-ui";
import StandardLayout from "src/pages/settings/components/StandardLayout";
import useBusiness from "src/hooks/useBusiness";
import usePositions from "src/hooks/usePositions";
import { faBookmark, faPaperPlane } from "@fortawesome/pro-regular-svg-icons";
import XeroLogo from "src/pages/business/integrations/assets/xero_logo.png";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import { useNavigate } from "react-router-dom";

const employeeOptions = [
  { label: "Full Time", value: "FULLTIME" },
  { label: "Part Time", value: "PARTTIME" },
  { label: "Casual", value: "CASUAL" },
];

const contractorOptions = [
  { label: "Labour Hire", value: "LABOURHIRE" },
  { label: "Non Employee", value: "NONEMPLOYEE" },
];

const incomeOptions = [
  { label: "Salary and Wages", value: "SALARYANDWAGES" },
  { label: "Working Holiday Maker", value: "WORKINGHOLIDAYMAKER" },
  { label: "Closely Held Payees", value: "CLOSELYHELDPAYEES" },
];

const NewStaffMember = () => {
  const [data, setData] = useState({ start_date: new Date() });
  const [saveShiftlyLoading, setSaveShiftlyLoading] = useState(false);
  const [saveXeroLoading, setSaveXeroLoading] = useState(false);

  const [employmentBasisOptions, setEmploymentBasisOptions] = useState(employeeOptions);
  const [incomeTypeOptions, setIncomeTypeOptions] = useState(incomeOptions);

  const { allGroups, getAvailableLevels } = usePositions();

  const styling = useStyling(styles);
  const { activeBusiness } = useBusiness();
  const { activeLocation } = useShiftlyLocation();
  const formRef = useRef();
  const navigate = useNavigate();

  const {
    data: [integration],
    isLoading,
  } = useFetch({
    request: {
      id: "Integration.GetIntegrationForEmployeeOnboarding",
      entity: "Integration",
      method: "get",
      criteria: {
        business: activeBusiness?._id,
        type: "xero",
        status: "active",
      },
    },
    dependency: activeBusiness?._id,
  });

  const { post: createStaffMember, refresh } = useFetch({
    options: {
      onSuccess: () => {
        refresh("User.GetInternalStaff");
        navigate("/people?tab=internal");
      },
    },
  });

  const awardCodes = useMemo(
    () => activeBusiness?.industries?.map((i) => ({ label: i.award_code + " - " + i.name, value: i.award_code })),
    [activeBusiness]
  );

  const availableLevels = useMemo(() => {
    const levels = new Set();

    allGroups.forEach((group) => {
      const groupLevels = getAvailableLevels(group?._id);
      groupLevels.forEach((level) => {
        levels.add(level);
      });
    });

    return Array.from(levels);
  }, [allGroups, getAvailableLevels]);

  const awardStreams = useMemo(() => {
    const award = responsibilities[data?.award_code];

    if (!award) return [];

    return Object.keys(award).map((key) => ({ label: key, value: key }));
  }, [data?.award_code]);

  const onSubmit = useCallback(
    async (data, shiftlyOnly = false) => {
      const staffID = new mongoose.Types.ObjectId();
      const employeeOnboardID = new mongoose.Types.ObjectId();

      const payloads = [
        {
          entity: "InternalStaff",
          method: "create",
          data: { ...data, _id: staffID, location: activeLocation?._id },
        },
        {
          entity: "Employment",
          method: "create",
          data: {
            employee_onboard: employeeOnboardID,
            internalStaff: staffID,
            classification_level: data.award_level,
            income_stream: data.award_stream,
            location: activeLocation?._id,
            award_code: data.award_code,
            type: integration?.type || "shiftly",
            ...data,
          },
        },
        {
          entity: "TaxDeclaration",
          method: "create",
          data: {
            employee_onboard: employeeOnboardID,
            internalStaff: staffID,
            location: activeLocation?._id,
            ...data,
          },
        },
      ];

      if (!shiftlyOnly) {
        setSaveXeroLoading(true);
        payloads.push({
          entity: "EmployeeOnboard",
          method: "create",
          data: {
            _id: employeeOnboardID,
            status: "pending",
            internalStaff: staffID,
            integration: integration?._id,
            location: activeLocation?._id,
            sent_on: new Date(),
            last_reminded_on: new Date(),
          },
        });
      } else {
        setSaveShiftlyLoading(true);
      }

      await createStaffMember(payloads);

      setSaveXeroLoading(false);
      setSaveShiftlyLoading(false);
    },
    [activeLocation?._id, integration, createStaffMember]
  );

  return (
    <StandardLayout heading={<h1>Add a new staff member</h1>} breadcrumb={"People / "} size={"medium"}>
      <FormV2 onSubmit={onSubmit} data={data} setData={setData}>
        {isLoading ? (
          <div className={styling("loading-container")}>
            <LoadingSpinner loader={isLoading} />
          </div>
        ) : (
          <>
            <div className={styling("container")}>
              <div className={styling("half")}>
                <FormFlex>
                  <Input name={"first_name"} required />
                  <Input name={"last_name"} required />
                </FormFlex>
                <Input name={"email"} required />
                <Input name={"job_title"} label={"Job Title"} placeholder={"Job Title"} />
                <FormFlex>
                  <Input type={"date"} name={"start_date"} required label={"Start Date"} />
                  <Input type={"number"} name={"hourly_rate"} label={"Hourly Rate"} placeholder={"0.00"} />
                </FormFlex>
                <RadioButtons
                  name={"employment_type"}
                  options={[
                    { label: "Employee", value: "EMPLOYEE" },
                    { label: "Contractor", value: "CONTRACTOR" },
                  ]}
                  onChange={(value) => {
                    if (value === "EMPLOYEE") {
                      setEmploymentBasisOptions(employeeOptions);
                      setIncomeTypeOptions(incomeOptions);
                    } else {
                      setEmploymentBasisOptions(contractorOptions);
                      setIncomeTypeOptions(contractorOptions);
                    }
                    setData((prev) => ({ ...prev, employment_type: value, employment_basis: null, income_type: null }));
                  }}
                  label={"Is this person an employee or a contractor?"}
                  required
                  mt={"5px"}
                />
              </div>
              <div className={styling("half")}>
                <FormFlex>
                  <Dropdown
                    name={"employment_basis"}
                    label={"Employment Basis"}
                    placeholder={"Select Employment Basis"}
                    required
                    options={employmentBasisOptions}
                    disabled={!data.employment_type}
                    onChange={(value) => {
                      if (data.employment_type === "CONTRACTOR") {
                        setData((prev) => ({ ...prev, income_type: value, employment_basis: value }));
                      }
                    }}
                  />
                  <Dropdown
                    name={"income_type"}
                    placeholder={"Select Income Type"}
                    label={"Income Type"}
                    options={incomeTypeOptions}
                    required
                    disabled={data.employment_type === "CONTRACTOR" || !data.employment_type}
                  />
                </FormFlex>

                <Dropdown
                  name={"award_code"}
                  label={"Award Code"}
                  link={"https://www.com."}
                  placeholder={"Select an Award Code"}
                  options={awardCodes}
                />
                <FormFlex>
                  <Dropdown
                    name={"award_stream"}
                    label={"Award Stream"}
                    disabled={!data?.award_code}
                    options={awardStreams}
                  />
                  <Dropdown
                    name={"award_level"}
                    label={"Award Level"}
                    placeholder={"Select an Award Level"}
                    disabled={!data?.award_code}
                    options={availableLevels}
                  />
                </FormFlex>
                <Input name={"notes"} label={"Notes"} placeholder={"Notes"} />
                <Checkbox mt={"25px"} name={"is_authorised_to_approve_leave"} label={"Authorised to approve leave?"} />
                <Checkbox name={"is_authorised_to_approve_timesheets"} label={"Authorised to approve timesheets?"} />
              </div>
            </div>
            <div className={styling("footer")}>
              <Button
                theme={"secondary-transparent"}
                onClick={() => {
                  formRef.current?.reset();
                  setData({ start_date: new Date() });
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
              <div className={styling("action-buttons")}>
                <Button
                  theme={"secondary-outline"}
                  icon={faBookmark}
                  loading={saveShiftlyLoading}
                  onClick={() => onSubmit(data, true)}
                >
                  Save to Shiftly
                </Button>
                <Button
                  theme={"primary"}
                  icon={integration && faPaperPlane}
                  loading={saveXeroLoading}
                  onClick={() => onSubmit(data)}
                >
                  {!integration ? "Upgrade to integrate" : "Next: Send to Employee"}
                </Button>
                {!integration && (
                  <div className={styling("integration-icons")}>
                    <img src={XeroLogo} alt="xero-logo" />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </FormV2>
    </StandardLayout>
  );
};

export default NewStaffMember;
