import { Toggle, Button, Each, useFetch } from "shiftly-ui";
import StandardLayout from "../../../settings/components/StandardLayout";
import styles from "./BusinessAccountBillingAndPayment.module.css";
import { faDollarSign, faFileInvoice, faInstitution, faMoneyBill } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { faChevronRight, faPlus, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import BusinessAccountAddNewPaymentMethod from "./BusinessAccountAddNewPaymentMethod";
import BusinessAccountPaymentMethodDisplay from "./components/BusinessAccountPaymentMethodDisplay";
import useBusiness from "src/hooks/useBusiness";

const links = [
  { text: "Payment History", icon: faFileInvoice, link: "/business-account/billing-and-payment/payment-history" },
  { text: "Pricing Plans", icon: faDollarSign, link: "/business-account/billing-and-payment/pricing" },
];

const AccountBillingAndPayment = () => {
  const [tab, setTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { activeBusiness } = useBusiness();

  const { data: paymentMethods, isFetching } = useFetch({
    request: {
      entity: "PaymentMethod",
      method: "get",
      criteria: {
        business: activeBusiness?._id,
      },
      id: "PaymentMethod.GetPaymentMethods",
    },
    dependency: activeBusiness?._id,
    options: {
      select: (data) => {
        return data.sort((a, b) => {
          if (a.is_default) return -1;
          if (b.is_default) return 1;
          return 0;
        });
      },
    },
  });

  return (
    <StandardLayout
      heading={
        <h1>
          Billing & <span>Payment</span>
        </h1>
      }
      breadcrumb={"Business Settings /"}
      returnLink={"/business-account"}
      size="medium"
    >
      <BusinessAccountAddNewPaymentMethod showModal={showModal} setShowModal={setShowModal} tab={tab} setTab={setTab} />
      <div className={styles["container"]}>
        <div className={styles["payment-method-container"]}>
          <h3>Payment Method</h3>
          <div className={styles["tabs-container"]}>
            <Toggle
              tab={tab}
              setTab={setTab}
              tabs={[
                {
                  text: "Account Details",
                  icon: <FontAwesomeIcon icon={faInstitution} />,
                },
                {
                  text: "Card Details",
                  icon: <FontAwesomeIcon icon={faMoneyBill} />,
                },
              ]}
            />
          </div>
          {isFetching ? (
            <div className={styles["loading-container"]}>
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          ) : (
            <div className={styles["tab-content"]}>
              {tab === 0 ? (
                <Each
                  of={paymentMethods.filter((method) => method.type === "au_becs_debit")}
                  render={({ key, ...method }) => <BusinessAccountPaymentMethodDisplay key={key} {...method} />}
                />
              ) : (
                <Each
                  of={paymentMethods.filter((method) => method.type === "card")}
                  render={({ key, ...method }) => <BusinessAccountPaymentMethodDisplay key={key} {...method} />}
                />
              )}
            </div>
          )}
          <div className={styles["add"]}>
            <Button className={styles["add-btn"]} iconSide="left" icon={faPlus} onClick={() => setShowModal(true)}>
              Add New Payment Method
            </Button>
          </div>
        </div>
        <div className={styles["links-container"]}>
          <Each of={links} render={({ key, ...link }) => <BillingLink key={key} {...link} />} />
        </div>
      </div>
    </StandardLayout>
  );
};
const BillingLink = ({ icon, text, link }) => {
  const navigate = useNavigate();
  return (
    <div className={styles["link"]} onClick={() => navigate(link)}>
      <div className={styles["details"]}>
        <div className={styles["icon-container"]}>
          <FontAwesomeIcon icon={icon} />
        </div>
        <p>{text}</p>
      </div>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
};
export default AccountBillingAndPayment;
